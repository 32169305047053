import React from "react";
import { useState } from "react";
import LocalService from "../services/LocalService";

import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import SendIcon from '@mui/icons-material/Send';
 
 
const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
  },
});
 
 
function AnswerTheQuestion() {
  const [formData, setFormData] = useState({
    response: "",
    message:"",
  });
 
  const runSubnet = () => {
    setFormData({response: "Getting the answer....", message: ""});
    LocalService.queryAnswer(formData.input)
      .then(response => {
         console.log(response.data);
         setFormData(response.data);
      })
      .catch(e => {
        console.log(e);
        setFormData({response: "Error", message: e.message});
      });
  };

  // const runAnswer = () => {
  //   LocalService.getAnswer(formData.input)
  //     .then(response => {
  //        console.log(response.data);
  //        response.data.ipAddresses = '' + response.data.ipAddresses;
  //        setFormData(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };
 
 
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData({...formData, input: value});
    console.log(formData)
  };
 
 
  const handleSubmit = (event) => {
    event.preventDefault();
 
    // Validate form data
    const errors = {};
 
    if (!formData.input) {
      errors.input = "Please enter a valid ip/cidr";
    }
 
    // Submit form data if there are no errors
    if (Object.keys(errors).length === 0) {
      runSubnet();
    }
  };
 
 
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h2" mb={3}></Typography>
      <Grid
        display="flex"
        sx={{
          minHeight: "100vh",
          width: "fit-width",
          margin: "auto",
        }}
      >
        <Grid container spacing={4} sx={{width:"fit-width",display:"flex",justifyContent:"left",}}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 4 }} elevation={3}>
              <Typography variant="h5" mb={3}>
                Let me answer your question, Ask me?
              </Typography>
          <Typography variant="body2" color="text.primary">
            Answer me attempts to answer questions about movies and tv series. 
            It suggests the youtube trailers related to the question.
            Examples: My favourite movie is "The Amazing Spider Man". Can you tell me something about it. Also provide me with its trailer
          </Typography>
              
                <Grid container spacing={2}>

                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth="true"
                      label="Your Question"
                      name="input"
                      variant="outlined"
                      margin="normal"
                      multiline="true"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SendIcon 
                            onClick={handleSubmit}
                            />
                          </InputAdornment>
                        ),
                      }}
            
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth="true"
                      label="Answer"
                      name="answer"
                      variant="outlined"
                      multiline="true"
                      value={formData.response || formData.message}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
 
 
export default AnswerTheQuestion;
