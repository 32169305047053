import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import LocalService from "../services/LocalService";
import { Typography } from "../components/shared/Typography";
import Box from '@mui/joy/Box';
import Radio from '@mui/joy/Radio';


const Cvss = () => {

  const [fld, setFld] = useState({
    query: '',
    format: 'all',
    response: "",
    message:"",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFld({...fld, [name]: value});
    console.log(fld)
  };

  const runCVSSAll = () => {
    LocalService.queryCVSSAll(fld.query)
      .then(response => {
         console.log(response.data);
         setFld({query: fld.query, format: fld.format, response: JSON.stringify(response.data.response), message: response.data.message});
      })
      .catch(e => {
        console.log(e);
        setFld({response:"", message: e.message, format: fld.format, query: fld.query});
      });
  };

  const runCVSSOpenAI = () => {
    LocalService.queryCVSSOpenAI(fld.query)
      .then(response => {
         console.log(response.data);
         setFld({query: fld.query, format: fld.format, response: response.data.response, message: response.data.message});
      })
      .catch(e => {
        console.log(e);
        setFld({response:"", message: e.message, format: fld.format, query: fld.query});
      });
  };

  const runCVSSMistral = () => {
    LocalService.queryCVSSMistral(fld.query)
      .then(response => {
         console.log(response.data);
         setFld({query: fld.query, format: fld.format, response: response.data.response, message: response.data.message});
      })
      .catch(e => {
        console.log(e);
        setFld({response:"", message: e.message, format: fld.format, query: fld.query});
      });
  };

  const runCVSSGroq = () => {
    LocalService.queryCVSSGroq(fld.query)
      .then(response => {
         console.log(response.data);
         setFld({query: fld.query, format: fld.format, response: response.data.response, message: response.data.message});
      })
      .catch(e => {
        console.log(e);
        setFld({response:"", message: e.message, format: fld.format, query: fld.query});
      });
  };

  const runCVSSCohere = () => {
    LocalService.queryCVSSCohere(fld.query)
      .then(response => {
         console.log(response.data);
         setFld({query: fld.query, format: fld.format, response: response.data.response, message: response.data.message});
      })
      .catch(e => {
        console.log(e);
        setFld({response:"", message: e.message, format: fld.format, query: fld.query});
      });
  };

  const handleSubmit = (event) => {
    setFld({response: "Getting the CVSS score....", message: "", format: fld.format, query: fld.query});
    event.preventDefault();
    if ( fld.format === 'all' ) {
      runCVSSAll();
    } else if ( fld.format === 'openai' ) {
      runCVSSOpenAI();
    } else if ( fld.format === 'mistral' ) {
      runCVSSMistral();
    } else if ( fld.format === 'groq' ) {
      runCVSSGroq();
    } else if ( fld.format === 'cohere' ) {
      runCVSSCohere();
    } else {
      setFld({response:"", message: "Unknown Format", format: 'all', query: fld.query});
    }
  };

  return (
    <div>
    <div style={{ width: '100%' }}>
        <Typography variant="h6" fontWeight={400}>
          CVSS Score calculation from LLM Models.
        </Typography>
        <Typography variant="body2" color="text.primary">
            CVSS calculation based on different LLM models.  
            The prompts have been adjusted to give consistent CVSS base v3 score for same queries.
          </Typography>
        <Box sx={{ display: 'flex', gap: 2 }} style={{ marginBottom: "10px", marginTop: "10px"}}>
          <Radio
            checked={fld.format === 'all'}
            onChange={handleInputChange}
            value="all"
            label="All"
            name="format"
          />
          <Radio
            checked={fld.format === 'openai'}
            onChange={handleInputChange}
            value="openai"
            label="OpenAI"
            name="format"
          />
          <Radio
            checked={fld.format === 'mistral'}
            onChange={handleInputChange}
            value="mistral"
            label="Mistral"
            name="format"
          />
          <Radio
            checked={fld.format === 'groq'}
            onChange={handleInputChange}
            value="groq"
            label="Groq"
            name="format"
          />
          <Radio
            checked={fld.format === 'cohere'}
            onChange={handleInputChange}
            value="cohere"
            label="Cohere"
            name="format"
          />
        </Box>
        <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth="true"
                      label="CVSS Query"
                      name="query"
                      variant="outlined"
                      margin="normal"
                      multiline="true"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SendIcon 
                            onClick={handleSubmit}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
        
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth="true"
                      label="CVSS Score"
                      name="answer"
                      variant="outlined"
                      multiline="true"
                      value={fld.response || fld.message}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

    </div>
    </div>
  )
}

export default Cvss;
