import http from "../http-graph";

const getAnswer = (ip) => {
  return http.get(`/chatbot/question?q=${ip}`);
};

const queryAnswer = (q) => {
  return http.post('/chatbot/question', {question: q}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
};

const queryCVSSAll = (query) => {
  return http.post('/cvss/multiple', {question: query}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const queryCVSSOpenAI = (query) => {
  return http.post('/cvss/openai', {question: query}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const queryCVSSMistral = (query) => {
  return http.post('/cvss/mistral', {question: query}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const queryCVSSGroq = (query) => {
  return http.post('/cvss/groq', {question: query}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const queryCVSSCohere = (query) => {
  return http.post('/cvss/cohere', {question: query}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const LocalService = { 
  getAnswer,
  queryAnswer,
  queryCVSSAll,
  queryCVSSOpenAI,
  queryCVSSMistral,
  queryCVSSGroq,
  queryCVSSCohere,
};


export default LocalService;
